import React from 'react';
import Hero from 'components/page-survey/hero';
import Form from 'components/page-survey/form';
import Meta from 'meta';

export default function SurveyPage(props) {
  return (
    <>
      <Meta canonical="https://cxncollective.com/survey/" />
      <main>
        <Hero />
        <Form />
      </main>
    </>
  );
}
