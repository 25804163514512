import React from 'react';
import s from './radio-group.module.scss';

export default function RadioGroup({
  options,
  name,
  value,
  setter,
  hanlder,
  ...props
}) {
  return (
    <div className={s.group}>
      {options.map((el, i) => (
        <div className={`${s.radioWrapper}`} key={`${el}-${i}`}>
          <input
            type="radio"
            name={name}
            id={`${name}-${i}`}
            checked={value === el}
            onChange={e => hanlder(e, setter)}
            value={el}
            className={`${s.radio}`}
          />
          <label className={s.radioLabel} htmlFor={`${name}-${i}`}>
            {el}
          </label>
        </div>
      ))}
    </div>
  );
}
