import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import s from './hero-survey.module.scss';

export default function HeroSurvey(props) {
  return (
    <Container className={s.root}>
      <div className={s.overlay} />
      <div className={s.imgWrapper}>
        <Img className={s.img} src="suvery-hero" alt="cxn office" />
      </div>
      <div className={s.content}>
        <Headline h1 dash center white className={s.title}>
          CUSTOMER SATISFACTION QUESTIONNAIRE
        </Headline>
        <p className={s.text}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo.
        </p>
      </div>
    </Container>
  );
}
