import React from 'react';
import s from './radio-group.module.scss';

export default function RadioGroup({
  title,
  options,
  name,
  value,
  setter,
  hanlder,
  ...props
}) {
  return (
    <div className={s.group}>
      <p className={s.title}>{title}</p>
      <div className={s.options}>
        {options.map((el, i) => (
          <div className={`${s.radioWrapper}`} key={`${el}-${i}`}>
            <label
              className={`${s.radioLabel} ${i === 0 ? s.first : ''} ${
                i === 4 ? s.last : ''
              }`}
              htmlFor={`${name}-${i}`}
              style={
                i !== 0 && i !== 4 ? { display: 'none' } : { display: 'block' }
              }
            >
              {el}
            </label>
            <input
              type="radio"
              name={name}
              id={`${name}-${i}`}
              checked={value === el}
              onChange={e => hanlder(e, setter)}
              value={el}
              className={`${s.radio}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
