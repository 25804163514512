import React, { useState } from 'react';
import Container from 'container';
import { navigate } from 'gatsby';
import axios from 'axios';
import Input from 'shared/form-input';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import RadioGroup from './radio-group';
import RadioRow from './radio-row';
import s from './form.module.scss';

export default function SurveyForm({ title, subtitle, ...props }) {
  // lists
  const expectations = [
    'Extremely well',
    'Somewhat well',
    'Not so well',
    'Not at all well'
  ];
  const howOften = ['Daily', 'Weekly', 'Bi-weekly', 'Monthly'];
  const rates = [
    'Very satisfied',
    'Satisfied',
    'Neutral',
    'Dissatisfied',
    'Very Dissatisfied'
  ];
  const rateParameters = [
    'Pre-purchase support',
    'Post-purchase support',
    'Issue resolution speed',
    'Subject matter expertise of support executive',
    'Post-resolution followup procedure'
  ];
  // Inputs
  const [overall, setOverall] = useState('9');
  const [expectation, setExpectation] = useState('Extremely well');
  const [often, setOften] = useState('Daily');
  const [quality, setQuality] = useState(rates[0]);
  const [speed, setSpeed] = useState(rates[0]);
  const [cost, setCost] = useState(rates[0]);
  const [money, setMoney] = useState(rates[0]);
  const [reliability, setRelieability] = useState(rates[0]);
  const [prePurchase, setPrePurchase] = useState(rates[0]);
  const [postPurchase, setPostPurchase] = useState(rates[0]);
  const [resolutionSpreed, setResolutionSpreed] = useState(rates[0]);
  const [expertise, setExpertise] = useState(rates[0]);
  const [followUp, setFollowup] = useState(rates[0]);
  // const name = useFormInput('');
  // const email = useFormInput('');
  // const phone = useFormInput('');
  const comment = useFormInput('');

  // Error handlers
  // const emailErrors = useErrors({ email: email.state });
  // const nameErrors = useErrors({ name: name.state });
  // const phoneErrors = useErrors({ phone: phone.state });
  // const commentErrors = useErrors({ comment: comment.state });

  // input hanlders
  function handleCheck(e, setter) {
    setter(e.target.value);
  }
  // Sumbit handlers
  // const submitReady = useSubmitReady({
  //   // name: name.state,
  //   // email: email.state,
  //   // comment: comment.state
  // });
  const handleSubmit = async e => {
    e.preventDefault();
    const payload = {
      overall: Number.parseInt(overall, 10) + 1,
      expectation,
      often,
      quality,
      speed,
      cost,
      money,
      reliability,
      prePurchase,
      postPurchase,
      resolutionSpreed,
      expertise,
      followUp,
      page: window.location.pathname,
      comment: comment.state.value,
      date: new Date().toDateString()
    };
    await axios(
      'https://cxn-agency.firebaseio.com/customer-satisfaction-surveys.json',
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        data: payload
      }
    )
      .then(x => {
        navigate('/thanks/');
      })
      .catch(err => console.log(err));
  };
  return (
    <Container className={s.root}>
      <form className={`${s.form}`} onSubmit={handleSubmit}>
        <p className={s.text}>
          Considering your <span className={s.underline}>complete</span>{' '}
          experience with our company, how likely would you be to recommend us
          to a friend or colleague?
        </p>
        <div className={s.overallWrapper}>
          {new Array(10).fill('overall').map((el, i) => (
            <div className={`${s.squareRadioWrapper}`} key={`overall-${el}-${i}`}>
              <label className={s.radioSquareLabel} htmlFor={`overall-${i}`}>
                {i + 1}
              </label>
              <input
                type="radio"
                name="overall"
                id={`overall-${i}`}
                checked={overall === `${i}`}
                onChange={e => handleCheck(e, setOverall)}
                value={`${i}`}
                className={`${s.radioSqaure}`}
              />
            </div>
          ))}
        </div>

        <p className={s.text}>
          Please rate our Products or Services for the following parameters:
        </p>
        <div className={s.ratesWrapper}>
          {rates.map((el, i) => (
            <p className={s.rate} key={`rates-${el}-${i}`}>
              {el}
            </p>
          ))}
        </div>
        <RadioRow
          options={rates}
          name="quality"
          value={prePurchase}
          setter={setPrePurchase}
          hanlder={handleCheck}
          title="Quality"
        />
        <RadioRow
          options={rates}
          name="speed"
          value={postPurchase}
          setter={setPostPurchase}
          hanlder={handleCheck}
          title="Speed"
        />
        <RadioRow
          options={rates}
          name="cost"
          value={resolutionSpreed}
          setter={setResolutionSpreed}
          hanlder={handleCheck}
          title="Cost effectiveness"
        />
        <RadioRow
          options={rates}
          name="money"
          value={expertise}
          setter={setExpertise}
          hanlder={handleCheck}
          title="Value for money"
        />
        <RadioRow
          options={rates}
          name="reliability"
          value={followUp}
          setter={setFollowup}
          hanlder={handleCheck}
          title="Reliability"
        />
        <p className={s.text}>
          How well did our products meet your expectations?
        </p>
        <RadioGroup
          options={expectations}
          name="expectations"
          value={expectation}
          setter={setExpectation}
          hanlder={handleCheck}
        />
        <p className={s.text}>
          Please rate our Products or Services for the following parameters:
        </p>
        <div className={s.ratesWrapper}>
          {rates.map(el => (
            <p className={s.rate} key={`${el}-second`}>{el}</p>
          ))}
        </div>
        <RadioRow
          options={rates}
          name={rateParameters[0]}
          value={quality}
          setter={setQuality}
          hanlder={handleCheck}
          title={rateParameters[0]}
        />
        <RadioRow
          options={rates}
          name={rateParameters[1]}
          value={speed}
          setter={setSpeed}
          hanlder={handleCheck}
          title={rateParameters[1]}
        />
        <RadioRow
          options={rates}
          name={rateParameters[2]}
          value={cost}
          setter={setCost}
          hanlder={handleCheck}
          title={rateParameters[2]}
        />
        <RadioRow
          options={rates}
          name={rateParameters[3]}
          value={money}
          setter={setMoney}
          hanlder={handleCheck}
          title={rateParameters[3]}
        />
        <RadioRow
          options={rates}
          name={rateParameters[4]}
          value={reliability}
          setter={setRelieability}
          hanlder={handleCheck}
          title={rateParameters[4]}
        />
        <p className={s.text}>How often do you use our product / service?</p>
        <RadioGroup
          options={howOften}
          name="often"
          value={often}
          setter={setOften}
          hanlder={handleCheck}
        />
        <p className={s.text}>
          Please add any further comments / suggestions for us:
        </p>
        <Input
          textarea
          round
          className={s.textArea}
          value={comment.state.value}
          {...comment.events}
        />
        <div className={s.buttonWrapper}>
          <button className={s.button} type="submit" disabled={false}>
            SUBMIT
          </button>
        </div>
      </form>
    </Container>
  );
}
